import { type RouteName } from "@/constants/routes";

export default function useRouteValidator() {
  const route = useRoute();

  const isRouteDisallowed = (allowedRoutes: RouteName[]) => {
    return computed(() => !allowedRoutes.includes(route.name as RouteName))
      .value;
  };

  return { isRouteDisallowed };
}
